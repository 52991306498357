import React from "react";
import RightSidebar from "../../components/Social/RightSideBar";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { useState } from "react";


import 'stream-chat-react/dist/css/v2/index.css';
import ChatSystem from "../../components/Social/ChatSystem";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ChatSuggestedUsers from "../../components/Social/ChatSuggestedUsers";


const ChatPage = () => {
    const { isAuthenticated } = useSelector((state) => state.user);

    return (
        <>
            {!isAuthenticated ? (
                <LoginPage />
            ) : (
                <div className="my-6 grid grid-cols-1 md:grid-cols-17" style={{ maxHeight: "100vh" }}>
                    {/* <div className=" hidden md:block">

                    </div> */}
                        <ChatSystem />
                    <div className="col-span-3 block px-2 dark:text-[#e4e6eb] hidden md:block">
                        <div className="1100px:block hidden dark:border-2 border-solid dark:bg-[#262627] dark:border-[#5c5757] rounded-md p-4 space-y-4">
                            <h2 className="font-bold text-center text-2xl pb-[8px] dark:text-[#FFFB00]">Invoices</h2>
                            <Link to={`/manage-invoices/new`} className="whitespace-nowrap h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                            >Create Invoice</Link>
                            <Link to={`/manage-invoices`} className="whitespace-nowrap h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                            >View Invoices</Link>

                        </div>

                        <ChatSuggestedUsers count={5}/>

                    </div>
                </div>
            )}
        </>
    );


};


export default ChatPage;
