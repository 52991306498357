import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
    Attachment as DefaultAttachment,
    Channel, ChannelList,
    Chat, MessageInput,
    MessageList, Window,
    useChannelActionContext, WithComponents, useMessageContext,
    MessageText,
    useComponentContext,
    useChatContext,
    useChannelPreviewInfo,
    useChannelStateContext,
    Avatar as DefaultAvatar
} from 'stream-chat-react';


import 'stream-chat-react/dist/css/v2/index.css';

import { IoMdArrowRoundBack } from "react-icons/io";
import './Chat.css';
import InappropriateContentDialog from "../Layout/InappropriateContentDialog";
import { Link } from "react-router-dom";
import BlockModal from "../Social/BlockModal";
import axios from "axios";
import { server } from "../../server";
import { BiBadgeCheck } from "react-icons/bi";

const ChatPage = () => {
    const { user, chatClient } = useSelector((state) => state.user);
    const [searchParams] = useSearchParams();
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(true);
    const [channelId, setChannelId] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [InappropriateContentDialogOpen, setInappropriateContentDialogOpen] = useState(false);

    useEffect(() => {
        if (chatClient) {
            setFilters({ type: 'messaging', members: { $in: [chatClient.user.id] } });

            if (searchParams.get("with") && searchParams.get("with") !== chatClient.user.id) {
                const newChannel = chatClient.channel('messaging', { members: [chatClient.user.id, searchParams.get("with")] });
                newChannel.watch().then((res) => {
                    setChannelId(res.channel.id);
                    setLoading(false);
                    setIsMobileNavVisible(false);
                }).catch((err) => {
                    setLoading(false);
                });
            } else {
                // user is not chatting with anyone
                setLoading(false);
            }
        }
    }, [searchParams.get("with"), chatClient?.user?.id]);

    useEffect(() => {
        document.querySelectorAll('.footer-class').forEach(el => {
            el.style.display = "none";
        });
        // document.body.style.overflow = 'hidden';
        const mobileChannelList = document.querySelector('.mobile-channel-list');
        const chatView = document.querySelector('.mobile-chat-view');
        // console.log("trasitioning");
        if (isMobileNavVisible && mobileChannelList) {
            mobileChannelList.classList.add('show');
            setTimeout(() => {
                chatView.classList.add('hide');
            }, 500);

        } else if (!isMobileNavVisible && mobileChannelList) {
            mobileChannelList.classList.remove('show');
            chatView.classList.remove('hide');
        }
        return () => {
            document.querySelectorAll('.footer-class').forEach(el => {
                el.style.display = "block";
            });
            // document.body.style.overflow = 'auto';
        }
    }, [isMobileNavVisible]);


    function transformAvatarUrl(url) {
        const parts = url.split("/");
        const insertPart = "ar_1.0,c_thumb,g_auto,f_auto/r_max";
    
        if(parts.length < 6) {
            throw new Error("Invalid URL");
        }
    
        parts.splice(6, 0, insertPart);
        return parts.join("/");
    }

    
    const MenuIcon = ({ title }) => {
        return (
            <>
                <span onClick={() => setIsMobileNavVisible(true)}>
                    <IoMdArrowRoundBack color="#000" size="30" />
                </span>
            </>
        );
    };

    const CustomMessage = (props) => {
        const { renderText,
            handleAction
        } = props;
        const { message,
            threadList,

        } = useMessageContext();
        const { Attachment = DefaultAttachment
        } = useComponentContext();
        return (
            <div className="flex flex-row items-center py-2 ">
                {message.user && (
                    <img className="h-[55px] w-[55px] rounded-full" src={transformAvatarUrl(message.user.image)} alt="" />
                )}
                <div className="flex flex-col justify-center ml-4 max-w-[250px] 800px:max-w-full">
                    <MessageText message={message} renderText={renderText} />
                    {message.attachments?.length && !message.quoted_message ? (

                        <Attachment
                            actionHandler={handleAction}
                            attachments={message.attachments}
                        />
       
                    ) : null}
                </div>
            </div>
        );
    };

    const ChannelInner = () => {
        const { sendMessage } = useChannelActionContext();
        const overrideSubmitHandler = (message, cid) => {
            // regex the message for emails and phone numbers and display a warning dialog if present
            const emailRegex = /\S+@\S+\.\S+/;
            const phoneRegex = /(\d{3})\D*(\d{3})\D*(\d{4})/;
            const emailMatch = emailRegex.test(message.text);
            const phoneMatch = phoneRegex.test(message.text);
            if (emailMatch || phoneMatch) {
                setInappropriateContentDialogOpen(true);
                message.text = message.text.replace(emailRegex, '[email removed by ConnectSiimply]').replace(phoneRegex, '[phone number removed by ConnectSiimply]');
            }
            sendMessage(message);
        };
        return (
            <>
                <Window>
                    <CustomChannelHeader MenuIcon={MenuIcon} />
                    <WithComponents overrides={{
                        Message: CustomMessage,
                        Attachment: CustomAttachment,

                    }}>
                        <MessageList messageActions={['edit', 'delete', 'quote', 'react']} />
                    </WithComponents>

                    <MessageInput overrideSubmitHandler={overrideSubmitHandler} disableMentions={true} />
                </Window>
            </>
        );
    };

    if (!chatClient || !filters || loading || !user) {
        return <div className="col-span-10 bg-[#fafafa] dark:bg-black dark:text-white" style={{ height: "70vh", gridColumnStart: "span 14", gridColumnEnd: "span 14", gridColumn: "span 14 / span 14" }}>
            Loading chat please wait....
        </div>


    }

    const sort = { last_message_at: -1 };

    const options = {
        limit: 100,
        presence: true
    };

    const CustomAttachment = (props) => {
        const { attachments } = props;
        const [attachment] = (attachments || [])
        if (attachment?.type === 'invoice') {
            return (
                <div className="str-chat__message-text">
                    Click to view invoice:
                    <a href={attachment.link} rel="noreferrer" target="_blank" className="pl-1 dark:text-[#FFFB00] text-[#b1ae00]">
                        {attachment.title}
                    </a>
                </div>
            );
        }

        return <DefaultAttachment {...props} />;
    };


    const CustomChannelPreview = (props) => {
        const {
            channel,
            displayImage,
            displayTitle,
            latestMessagePreview,
            setActiveChannel,
            unread,
            active,
        } = props;
        const [isPremium, setIsPremium] = useState(false);

        const onlineStatus = Object.entries(channel.state.members).find(([id]) => id !== user._id)[1].user.online;
        const otherUserId = Object.entries(channel.state.members).find(([id]) => id !== user._id)[1].user.id;
        const handleClick = () => {
            setActiveChannel?.(channel);
        };

        useEffect(() => {
            // Fetch the premium status of the other user
            IsPremium(otherUserId).then(setIsPremium);
        }, [otherUserId]);

        let avatarURL = displayImage && displayImage.length > 0 ? transformAvatarUrl(displayImage) : "https://res.cloudinary.com/dljapfuhk/image/upload/v1705887059/avatars/ea9gyqgdzmtnfr2cwe9t.jpg";

        return (
            <button
                className={`py-2 px-2 channel-preview ${active ? "str-chat__channel-preview-messenger--active" : ""} w-full flex items-center space-x-4`}
                // Do not disable as this breaks the mobile view
                // disabled={active}
                onClick={handleClick}
            >
                <div className="relative min-w-[55px] w-[55px]">
                    <img className="h-[55px] w-[55px] rounded-full" src={avatarURL} alt="" />
                    {onlineStatus && (
                        <span className="absolute bg-[#1AEF0F] w-3 h-3 rounded-full bottom-0 right-0"></span>
                    )}
                </div>
                <div className="flex flex-col text-left">
                    <div className="flex items-center flex-row">
                        <div className={`${unread > 0 ? "font-bold" : "font-semibold"}`}>{displayTitle}</div>
                        {isPremium ? <BiBadgeCheck size="16" className="dark:text-[#FFFB00] ml-1" /> : ""}
                    </div>
                    <div className={`text-sm text-gray-500 dark:text-white ${unread > 0 ? "font-bold" : ""}`}>{latestMessagePreview}</div>
                </div>
            </button>
        );
    };
    const IsPremium = async (userId) => {
        if (!userId) return false;
        try {
            const response = await axios.get(`${server}/user/user-info/${userId}?cache=true`);
            return response.data.user.isPremium;
        } catch (error) {
            console.error("Error fetching user info:", error);
            return false;
        }
    };


    const CustomChannelHeader = (props) => {
        const {
            Avatar = DefaultAvatar,
            image: overrideImage,
            live,
            MenuIcon,
            title: overrideTitle,
        } = props;
        const {
            openMobileNav
        } = useChatContext();
        const { channel } = useChannelStateContext('ChannelHeader');
        const {
            displayTitle,
            displayImage,
        } = useChannelPreviewInfo({
            channel,
        });
        const [onlineStatus, setOnlineStatus] = useState(false);
        const [isPremium, setIsPremium] = useState(false);


        const otherUser = Object.values(channel.state.members).find((member) => member.user.id !== user._id).user;
        const otherUserData = { "_id": otherUser.id, "name": otherUser.name }



        useEffect(() => {
            const updateChannelUsers = () => {
                setOnlineStatus(Object.entries(channel.state.members).find(([id]) => id !== user._id)[1].user.online);
            };

            updateChannelUsers();
            chatClient.on("user.presence.changed", updateChannelUsers);
            return () => {
                chatClient.off("user.presence.changed", updateChannelUsers);
            };
        }, [channel]);

        useEffect(() => {
            // Fetch the premium status of the other user
            IsPremium(otherUser.id).then(setIsPremium);
        }, [otherUser.id]);

        let avatarURL = displayImage && displayImage.length > 0 ? transformAvatarUrl(displayImage) : "https://res.cloudinary.com/dljapfuhk/image/upload/v1705887059/avatars/ea9gyqgdzmtnfr2cwe9t.jpg";
        return (
            <div className='str-chat__channel-header'>
                <button
                    className='str-chat__header-hamburger'
                    onClick={openMobileNav}
                >
                    <MenuIcon />
                </button>
                <div className="relative">
                    <img className="h-[55px] w-[55px] rounded-full" src={avatarURL} alt="" />
                    {onlineStatus && (
                        <span className="absolute bg-[#1AEF0F] w-3 h-3 rounded-full bottom-0 right-0"></span>
                    )}
                </div>
                <div className='str-chat__channel-header-end'>
                    <p className=' text-xl font-semibold flex flex-row items-center'>
                        {displayTitle}{' '}
                        {isPremium ? <BiBadgeCheck size="23" className="dark:text-[#FFFB00] ml-1" /> : ""}
                    </p>
                </div>

                <BlockModal userData={otherUserData} />
            </div>
        );
    };


    return (
        <>

            <Chat client={chatClient} theme={document.documentElement.classList.contains('dark') ? "str-chat__theme-dark" : ""}>
                <div onClick={() => setIsMobileNavVisible(false)} className="col-span-4 rounded mobile-channel-list show" style={{ height: "70vh" }}>
                    <div className="h-[10vh] dark:bg-black bg-white"
                    >
                        <div className="whitespace-nowrap h-full max-h-[54px] 800px:flex hidden justify-center items-center my-2 1100px:mx-10 mx-2 text-lg rounded-md text-black font-bold text-2xl bg-[#FFFB00]">
                            Speak with Experts
                        </div>
                        <div className="h-full max-h-[54px] block 800px:hidden w-full bg-white dark:bg-black py-2 px-4">
                            <Link to={`/manage-invoices`} className="flex h-full justify-center items-center border border-transparent text-xl font-bold rounded-full text-black bg-[#FFFB00]"
                            >Invoices</Link>
                        </div>
                    </div>
                    <div className="flex h-[60vh]">
                        <ChannelList Preview={CustomChannelPreview} customActiveChannel={channelId} filters={filters} sort={sort} options={options} />
                    </div>
                    <InappropriateContentDialog open={InappropriateContentDialogOpen} setOpen={setInappropriateContentDialogOpen} />

                </div>
                <div className="col-span-10 mobile-chat-view hide shadow rounded-lg" style={{ height: "70vh" }}>

                    <Channel>
                        <ChannelInner />

                    </Channel>
                </div>
            </Chat >


        </>
    );


};


export default ChatPage;
