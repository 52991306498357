import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { StreamChat } from 'stream-chat';


// load user
export const loadUser = () => async (dispatch) => {

  // Retrieve the token from the cookies
  const token = Cookies.get('token');
  if (token) {
    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    axios.get(`${server}/user/getuser`, config).then((resa) => {
      const streamUser = {
        id: resa.data.user._id,
        name: resa.data.user?.name,
        image: resa.data.user?.avatar?.url,
      }

      const chatClient = StreamChat.getInstance('udvbj7rp23wq', {
        timeout: 6000,
      });
      chatClient.connectUser(streamUser, Cookies.get('streamChatUserToken')).then((res) => {
        dispatch({
          type: "LoadUserSuccess",
          payload: resa.data.user,
          unreadCount: res.me.total_unread_count,
          unreadPlatformNotificationsCount: res.me.unread_platform_notifications_count,
          chatClient: chatClient
        });
        chatClient.on((event) => {
          if (event.type == "user.updated") {
            dispatch({
              type: "updateUserFromStream",
              unreadPlatformNotificationsCount: event.user.unread_platform_notifications_count,
            });
          }
          if (event.total_unread_count !== undefined) {
            dispatch({
              type: "UpdateUnreadCount",
              unreadCount: event.total_unread_count,
              // unreadPlatformNotificationsCount: event.unread_platform_notifications_count,
            });
          }
        });
      }).catch((err) => {
        dispatch({
          type: "LoadUserFail",
          payload: "Chat failed to connect",
        });
        console.log(err)
      })
    }).catch((err) => {
      dispatch({
        type: "LoadUserFail",
        payload: "User not found",
      });

    });
  } else {
    dispatch({
      type: "LoadUserFail",
      payload: "User not found",
    });

  }
};

// user update information
export const updateUserInformation =
  (name, email, title, phoneNumber, bio, publicLocation, chatEmailNotifications, linkedinURL, twitterHandle, instagramHandle, pinterestHandle, portfolioLink) => async (dispatch) => {
    try {
      dispatch({
        type: "updateUserInfoRequest",
      });
      // Retrieve the token from the cookies
      const token = Cookies.get('token');
      // Set up the authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      const { data } = await axios.put(
        `${server}/user/update-user-info`,
        {
          email,
          phoneNumber,
          name,
          title,
          bio,
          publicLocation,
          chatEmailNotifications,
          linkedinURL,
          twitterHandle,
          instagramHandle,
          pinterestHandle,
          portfolioLink
        }, config
      );

      dispatch({
        type: "updateUserInfoSuccess",
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: "updateUserInfoFailed",
        payload: error.response.data.message,
      });
    }
  };


// get all users --- admin
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersRequest",
    });
    // Retrieve the token from the cookies
    const token = Cookies.get('token');
    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    const { data } = await axios.get(`${server}/user/admin-all-users`, config);

    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFailed",
      payload: error.response.data.message,
    });
  }
};
